import { matchPath } from 'react-router-dom'

/**
 * Returns true if the current route is one of the public routes,
 * false otherwise.
 *
 * @param publicRoutes - An array of public route paths.
 */
export default function isPublicRoutes() {
  const publicRoutes = ['/shop'] // Define public routes here
  return publicRoutes.some((route) => matchPath(route, window.location.pathname))
}
