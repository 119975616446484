export function formModifiedValues<T extends object>(
  dirtyValues: Partial<Record<keyof T, boolean | undefined>>,
  data: T
): T {
  const modifiedValues = {} as T
  for (const key in dirtyValues) {
    if (dirtyValues[key]) {
      modifiedValues[key] = data[key]
    }
  }
  return modifiedValues
}
