export async function urlToFile(url: string, filename: string, mimeType: string): Promise<File | null> {
  try {
    const response = await fetch(url)
    const data = await response.blob()
    const metadata = { type: mimeType }
    const file = new File([data], filename, metadata)
    return file
  } catch (error) {
    console.error('Error converting URL to File:', error)
    return null
  }
}
