export const resizeImage = (file: File, dimensions: { width: number; height: number }): Promise<File> => {
  const { width, height } = dimensions
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = width
        canvas.height = height
        ctx?.drawImage(img, 0, 0, width, height)

        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, { type: 'image/jpeg' })

            resolve(resizedFile)
          }
        }, 'image/jpeg')
      }
      img.src = e.target?.result as string
    }
    reader.readAsDataURL(file)
  })
}
