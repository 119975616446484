import { RansackDirection, RansackSortType } from '@/graphql/purchasing/generated/purchasing_graphql'
import { SortOption } from '@/modules/shared/components/table/types'

/**
 *
 * @param preferences The user preferences that we fetch from the api
 * @param defaultSortOption The default sort which will be the `isDefault` from the list `sortOptions` you've provided for the table.
 * This will be used in case there is an issue with the user's sorting preferences.
 * @returns Returns the ransack sort.
 */
export const generateRansackSort = (
  valueJson: string,
  defaultSortOption?: SortOption,
  skipSortKeys?: string[]
): RansackSortType[] => {
  // Get the default sort
  const defaultSort = [
    { property: defaultSortOption?.key || '', direction: defaultSortOption?.direction || RansackDirection.Asc },
  ]

  if (valueJson) {
    // Get the sort preference from the JSON
    const sorting = JSON.parse(valueJson).sorting as SortOption
    if (sorting && sorting.key) {
      if (skipSortKeys && skipSortKeys.length > 0 && skipSortKeys.includes(sorting.key)) {
        return defaultSort
      }
      // Generate the ransack sort
      return [{ property: sorting.key, direction: sorting.direction }]
    } else {
      // Return the default in case there is an issue with the user's sorting preferences.
      return defaultSort
    }
  }
  // Return the default in case there is an issue with the user's sorting preferences.
  return defaultSort
}
