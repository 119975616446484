import { InputMaybe, PageInfo } from '@/graphql/purchasing/generated/purchasing_graphql'

type RansackArgs = {
  after?: InputMaybe<string>
  before?: InputMaybe<string>
  first?: InputMaybe<number>
  last?: InputMaybe<number>
}

type RefetchFn = (args: RansackArgs) => void
type FetchMoreFn = (args: { variables: RansackArgs }) => void

interface OnPreviousPageProps {
  startCursor: Pick<PageInfo, 'startCursor'>['startCursor']
  refetch: RefetchFn
  paginationResultsPerPage: number
}

export function onPreviousPage(pagination: OnPreviousPageProps) {
  const { startCursor, refetch, paginationResultsPerPage } = pagination
  refetch({
    before: startCursor,
    after: null,
    last: paginationResultsPerPage,
    first: null,
  })
}

interface OnNextPageProps {
  endCursor: Pick<PageInfo, 'endCursor'>['endCursor']
  refetch: RefetchFn
  paginationResultsPerPage: number
}

export function onNextPage(pagination: OnNextPageProps) {
  const { endCursor, refetch, paginationResultsPerPage } = pagination
  refetch({
    after: endCursor,
    before: null,
    first: paginationResultsPerPage,
    last: null,
  })
}

interface OnFetchMoreProps {
  endCursor: Pick<PageInfo, 'endCursor'>['endCursor']
  fetchMore: FetchMoreFn
  paginationResultsPerPage: number
}

export function onFetchMore(pagination: OnFetchMoreProps) {
  const { endCursor, fetchMore, paginationResultsPerPage } = pagination
  fetchMore({
    variables: {
      after: endCursor,
      before: null,
      last: null,
      first: paginationResultsPerPage,
    },
  })
}
