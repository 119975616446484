/**
 * Converts a boolean string to a boolean value.
 * @param value - The boolean string to convert.
 * @returns The boolean value.
 */
export default function booleanStringToBoolean(value: string) {
  const lowerCaseValue = value.toLowerCase()
  if (lowerCaseValue === 'true' || lowerCaseValue === '1') return true
  if (lowerCaseValue === 'false' || lowerCaseValue === '0') return false
  throw new Error("Invalid boolean string. Accepted values are 'true', 'false', '0', '1'.")
}
