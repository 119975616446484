/**
 * This is a queue for the shop page mutations. The debounce makes it possible to request two mutations at the same time.
 * By adding a mutation to the queue, we make sure that only one mutation is executed at a time.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mutationQueue: (() => Promise<any>)[] = []
let isExecuting = false

// runs each mutation in the queue one at a time
export async function executeQueuedMutations() {
  if (!isExecuting && mutationQueue.length > 0) {
    isExecuting = true
    const mutation = mutationQueue.shift()
    try {
      if (mutation) {
        await mutation()
      }
    } catch (error) {
      console.error('Error executing mutation:', error)
    } finally {
      isExecuting = false
      executeQueuedMutations() // Ensure we continue with the next mutation in the queue
    }
  }
}

// adds a mutation to the queue, must be added as an anonymous function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function addMutationToQueue(mutationFunction: () => Promise<any>) {
  mutationQueue.push(mutationFunction)
  executeQueuedMutations() // Begin executing mutations if not running already
}

// unit test helper functions
export function getQueueLength() {
  return mutationQueue.length
}
export async function waitForQueueToFinish() {
  while (isExecuting || mutationQueue.length > 0) {
    await new Promise((resolve) => setTimeout(resolve, 10))
  }
}
