type Maybe<T> = T | null | undefined

type DeepPartialNullable<T> = {
  [P in keyof T]?: DeepPartialNullable<T[P]> | null | undefined
}

export type Edge<TNode> = {
  node: TNode
}

export type Connection<TEdge> = {
  edges?: Edge<TEdge>[]
}

type PartialAndNullableEdge<TNode> = DeepPartialNullable<{
  node?: Maybe<TNode>
}>

type PartialAndNullableConnection<TNode> = DeepPartialNullable<{
  edges?: Maybe<PartialAndNullableEdge<TNode>>[]
}>

export function extractEdges<DataType>(connection?: PartialAndNullableConnection<DataType> | null): DataType[] {
  return (
    connection?.edges
      ?.map((edge) => edge?.node)
      .filter((node): node is DataType => node !== null && node !== undefined) || []
  )
}
