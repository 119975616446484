export const getCurrencyFormatOptions = (currency: string): Intl.NumberFormatOptions => {
  try {
    const options: Intl.NumberFormatOptions = { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol' }
    new Intl.NumberFormat('en', options)
    return options
  } catch (e) {
    return {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
    }
  }
}
