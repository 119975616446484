export enum Dates {
  Long = 'Do MMMM YYYY h:mma',
  Medium = 'dddd, MMMM Do YYYY',
  Short = 'DD MMM YYYY',
  ShortOrdinal = 'Do MMM YYYY',
  ShortReverse = 'MMM Do YYYY',
  FullMonthShortReverse = 'MMMM Do YYYY',
  ShortYear = 'YYYY-MM-DD',
  Time = 'h:mma',
  MonthYear = 'MMMM YYYY',
}
