import { NetworkStatus } from '@apollo/client'

export interface CheckNetworkStatus {
  setVariablesLoading: boolean
  refetchLoading: boolean
  fetchMoreLoading: boolean
  loading: boolean
}

// Calculates the network status, this will be obsolete when enums are provided by graphql
export const checkNetworkStatus = (networkStatus: NetworkStatus): CheckNetworkStatus => {
  return {
    setVariablesLoading: networkStatus === NetworkStatus.setVariables,
    refetchLoading: networkStatus === NetworkStatus.refetch,
    fetchMoreLoading: networkStatus === NetworkStatus.fetchMore,
    loading: networkStatus === NetworkStatus.loading,
  }
}
