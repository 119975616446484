import { Price, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ProductType } from '@/modules/shared/types/ProductType'
import { extractEdges } from '@/modules/shared/utils/extractEdges'

// Formats a Product array into a ProductType array which is used in the product pages
export function formatProductsToProductTypes(products: Product[]) {
  return products.map((product) => {
    const bestPrice = product.prices?.edges?.length ? product.prices?.edges[0]?.node : null
    return {
      productId: product.id,
      cataloguedProductId: product.id,
      unitPrice: bestPrice?.unitPrice,
      image: product.image,
      taxPercentage: bestPrice?.taxPercentage,
      availableQuotes: extractEdges<Price>(product.prices),
      product,
    }
  }) as ProductType[]
}
