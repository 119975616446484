import i18next from 'i18next'

const tooLongString = (max_length: number) => {
  return {
    message: i18next.t('form.error.tooLong', 'Maximum character length is {{ max_length }}', { max_length }),
  }
}

const invalidPhoneNumber = () => {
  return {
    message: i18next.t('form.error.invalidPhoneNumber', 'The number you have entered is not a valid phone number.'),
  }
}

const buyListNameEmpty = () => {
  return {
    message: i18next.t('form.error.buyListNameEmpty', 'The Buy List Name cannot be empty.'),
  }
}

const createReportTemplateNameEmpty = () => {
  return {
    message: i18next.t('form.error.createReportTemplateEmpty', 'The Report Name cannot be empty.'),
  }
}

const feedbackIsEmpty = () => {
  return {
    message: i18next.t('form.error.feedbackIsEmpty', 'The Feedback Field cannot be empty.'),
  }
}

export { tooLongString, invalidPhoneNumber, buyListNameEmpty, createReportTemplateNameEmpty, feedbackIsEmpty }
