import { Maybe } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'

export interface OrganisationInput {
  name: Maybe<string>
  parent?: Maybe<OrganisationInput>
}

export const generateOrgName = (organisation: Maybe<OrganisationInput>) => {
  if (!organisation) return null

  let org = organisation
  let name = org.name
  while (org.parent) {
    name = `${org.parent.name} > ${name}`
    org = org.parent
  }
  return name
}
