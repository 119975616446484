import Bowser from 'bowser'

// Versions from early 2022 to now will be supported
// Versions before 2022, users will get a warning but still can use the app
const CHROME_SUPPORTED_VERSION = '98.0' // https://chromereleases.googleblog.com/2022/01/
const SAFARI_SUPPORTED_VERSION = '15.4' // https://developer.apple.com/documentation/safari-release-notes/safari-15_4-release-notes
const FIREFOX_SUPPORTED_VERSION = '98.0' // https://www.mozilla.org/en-US/firefox/98.0/releasenotes/
const MICROSOFT_EDGE_SUPPORTED_VERSION = '98.0' // https://learn.microsoft.com/en-us/deployedge/microsoft-edge-relnote-archive-beta-channel#version-980110823-january-14

export function isBrowserSupported() {
  const browser = Bowser.getParser(window.navigator.userAgent)

  const isSupported = browser.satisfies({
    chrome: `>=${CHROME_SUPPORTED_VERSION}`,
    safari: `>=${SAFARI_SUPPORTED_VERSION}`,
    firefox: `>=${FIREFOX_SUPPORTED_VERSION}`,
    'Microsoft Edge': `>=${MICROSOFT_EDGE_SUPPORTED_VERSION}`,
  })

  return isSupported
}
